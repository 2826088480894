<template>
  <div>
    <el-upload :action="url" :before-upload="beforeUploadHandle" :on-success="successHandle" :show-file-list="false">
      <img v-if="value" style="width:40px;height:40px" :src="$imgPath+value">
      <el-button size="small" type="primary" style="margin-left: 10px;">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
  export default {
    props: ['value'],
    data () {
      return {
        url: this.$http.adornUrl(`/sys/oss/uploadImg?token=${this.$cookie.get('token')}`)
      }
    },
    methods: {
      // 上传之前
      beforeUploadHandle (file) {
        if (file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
          this.$message.error('只支持jpg、png、gif格式的图片！')
          return false
        }
        this.num++
      },
      // 上传成功
      successHandle (response, file, fileList) {
        if (response && response.code === 0) {
          this.value = response.filename
          this.$emit('input', response.filename)
        } else {
          this.$message.error(response.msg)
        }
      }
    }
  }
</script>
